import React from "react";
import { graphql, PageProps } from "gatsby";
import { JobsFeedQuery } from "codegen";
import { Layout } from "components";
import { ContactFormBlock, PostList, Loader } from "components/ACF";

interface ProjectFeedContext {
  pageNumber: number;
  humanPageNumber: number;
  skip: number;
  limit: number;
  numberOfPages: number;
  previousPagePath?: string;
  nextPagePath?: string;
}

const JobList = (props: PageProps<JobsFeedQuery, ProjectFeedContext>): JSX.Element => {
  const { location } = props;
  const { page, posts, categories, locations, wp } = props.data;
  const allPosts = posts.nodes.map((node) => node);
  const allCategories = categories.nodes.map((node) => node);
  const allLocations = locations.nodes.map((node) => node);

  // const {
  //   webAppSettings: { webAppSettings: globalContactBlock },
  // } = wp;

  const { numberOfPages, currentPageNumber } = props.pageContext;

  if (page) {
    const { acfLayout, seo, content } = page;

    return (
      <Layout pageTitle={page.title} seo={seo} className="job-template">
        <>
          {acfLayout?.layout?.length > 0 ? (
            <Loader
              data={acfLayout.layout.map(({ ...layout }) => ({
                ...layout,
                numberOfPages,
                currentPageNumber,
              }))}
              location={seo?.breadcrumbs}
              cptItems={allPosts}
              cptCategories={allCategories}
              cptLocations={allLocations}
              browserLocation={location}
            />
          ) : (
            <PostList
              cptItems={allPosts}
              cptCategories={allCategories}
              cptLocations={allLocations}
              browserLocation={location}
              data={{
                currentPageNumber: currentPageNumber,
                numberOfPages: numberOfPages,
              }}
            />
          )}
        </>
        {/* {globalContactBlock && globalContactBlock.row && <ContactFormBlock data={globalContactBlock} />} */}
      </Layout>
    );
  }
};

export default JobList;

export const query = graphql`
  query JobsFeed($id: String!, $offset: Int!, $postsPerPage: Int!) {
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      ...SEOPageQuery
      acfLayout {
        fieldGroupName
        layout {
          ...HeroQuery
          ...FlexibleBlockQuery
          ...PostListQuery
          ...FeaturedPostsQuery
          ...ContactFormBlockQuery
        }
      }
    }
    categories: allWpJobCategory {
      nodes {
        id
        name
        slug
        uri
        jobs {
          nodes {
            id
          }
        }
      }
    }
    locations: allWpJobLocation {
      nodes {
        id
        name
        slug
        uri
        jobs {
          nodes {
            id
          }
        }
      }
    }
    posts: allWpJob(sort: { fields: [date], order: DESC }, limit: $postsPerPage, skip: $offset) {
      nodes {
        ...JobFields
      }
    }
    # wp {
    #   webAppSettings {
    #     webAppSettings {
    #       row {
    #         contenttype
    #         formid
    #         content
    #         image {
    #           localFile {
    #             childImageSharp {
    #               gatsbyImageData(quality: 80, placeholder: BLURRED, width: 700)
    #             }
    #           }
    #         }
    #       }
    #     }
    #   }
    # }
  }
`;
